
import roles from "@/services/roles";
import RoleConfigurationModal from "@/components/modals/RoleConfigurationModal";

export default {
  components: { RoleConfigurationModal },
  props: {
  },
  data () {
    return {
      pagination:{
      page: 1,
      rowsPerPage: 10,
     },
      
      openModel: false,
      visible: false,
      rows: [],
      confirm: false,
      selectedRole: "",
      loading: false,
      columns: [
        // { name: "id", align: "center", label: "ID", field: "id", sortable: true },
        {
          name: "title",
          align: "left",
          label: this.$store.state.user.translate.role_name,
          field: "title",
          sortable: true,
        },
        { name: "description", label: this.$store.state.user.translate.description, field: "description" },
        { name: "actions", label: this.$store.state.user.translate.actions, field: "actions" }
      ], 
      // pagination: {
      //   rowsPerPage: 10,
      // },
      selected: [],
      filter: "",
    }
  },
  computed: {
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    }
  },
  mounted() {
    this.visible = true
    this.getRoles()
  },
  methods: {
    addNewRole() {
      this.selectedRole = null
      this.openModel = !this.openModel
    },
    editRoleDialog(role) {
      this.selectedRole = role
      this.openModel = !this.openModel
    },
    deleteRole(row) {
      this.selectedRole = row
      this.confirm = true
    },

    async deleteSelectedRole() {
      roles.deleteRole(this.selectedRole._id).then(() => {
        this.getRoles()
        this.$q.notify({
          message: this.$store.state.user.translate.deleted_successfully,
          color: 'green'
        })
        this.confirm = false
      }).catch((error) => {
        // console.log(error)
        if (error.response.data.message) {
          this.$q.notify({
            message: error.response.data.message,
            color: 'red'
          })
        } else {
          this.$q.notify({
            message: this.$store.state.user.translate.something_went_wrong,
            color: 'red'
          })
        }
        this.confirm = false
      })
    },

    updateUserRole() {
      this.getRoles()
      this.closeModel()
    },

    getRoles() {
      this.visible = true
      this.loading = true
      roles.getRoles().then((response) => {
        this.rows = response.data.response.roles.reverse()
        this.visible = false
        this.loading = false
      }).catch((error) => {
        // console.log(error)
        this.visible = false
        this.loading = false
        if (error.response.data.message) {
          this.$q.notify({
            message: error.response.data.message,
            color: 'red'
          })
        } else {
          this.$q.notify({
            message: this.$store.state.user.translate.something_went_wrong,
            color: 'red'
          })
        }
      })
    },
    closeModel() {
      this.openModel = false;
    }
  }
}
